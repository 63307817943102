<template>
  <section>
      <div class="content-header">
        <h2>The importance of exercise  </h2>
      </div>
      <div class="content-wrapper">
        <p>Given the abundant research on exercise, cognition, wellbeing, and academic performance, for example in this article on <a href="https://www.sciencedirect.com/science/article/abs/pii/S2211368116301929" target="_blank">incorporating exercise breaks during lectures</a>, as well as this one on <a href="https://www.apa.org/pubs/journals/releases/xlm-a0036577.pdf" target="_blank">walking and creative thinking</a>, consider incorporating <a href="https://www.ryerson.ca/recreation/programs/movement-breaks/" target="_blank">movement breaks</a> into your in-person or online lectures </p>
        <p>Feel free to experiment with your students with short movement breaks of 3, 6 or 9 minutes. You may be surprised at how good they (and you) feel after a quick break together!</p>
        <p>In the TEDx talk, <a href="https://www.youtube.com/watch?v=RnFt6HKrSmo" target="_blank">A key Ingredient in the Student Resilience Recipe</a>, Joanne Dominico, a learning strategist at Centennial College, proposes a creative way to enhance learning by combining fitness and studying. </p>
        <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/RnFt6HKrSmo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
        <!-- <div class="ratio ratio-16x9">
          <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/RnFt6HKrSmo" allowfullscreen></iframe>
        </div> -->
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '03',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
